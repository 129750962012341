.app-header {
  margin-bottom: 1rem;
}

.container-fluid {
  padding: 0;
}

.app-body > section:first-child,
.app-body > section:last-of-type {
  padding: 0 1rem 0 1rem;
}
.app-body section:first-child h3,
.app-body section:first-child h4 {
  text-align: center;
}

.client-search-results-item .fantasy,
.client-search-details-item .fantasy {
  font-style: italic;
  font-size: 80%;
}

.client-search-details-item .card-text {
  margin-bottom: 1.5rem;
}

.border-danger {
  border: 2px solid;
}

.ext_audit_data{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.ext_audit_container {
  display: grid;
  grid-template-columns: 1fr;
}

.inactive_item {
  background-color: #d3b0b0 !important;
}

button:disabled {
  cursor: not-allowed;
}