.manufactureDate svg {
  color: #f31616;
}

.expireDate svg {
  color: #f99614;
}

.table-striped tbody tr.inactive {
  background-color: tomato;
}
